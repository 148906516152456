<!--
 * @Description: quick demo - vue2 版本页面
 * @Date: 2022-03-14 16:56:36
 * @LastEditTime: 2022-03-29 17:01:32
-->
<template>
  <div id="app">
    <div class="content" :class="$isMobile && 'content-mobile'">
      <comp-room
        :sdkAppId="Number(sdkAppId)"
        :secretKey="secretKey"
        :userId="userId"
        :roomId="Number(roomId)"
        :cameraId="cameraId"
        :teacher_id="teacher_id"
        :room_info="room_info"
        :microphoneId="microphoneId"></comp-room>
    </div>
  </div>
</template>

<script>
import compRoom from '@/components/comp-room.vue';
import { clearUrlParam } from '@/utils/utils';

export default {
  name: 'App',
  components: {
    compRoom,
  },
  data() {
    return {
      sdkAppId: 1400650658,
      secretKey: '15936b05229966d766d1cc93cf02e0e89b40224c59b11b5360281d17a949a1e0',
      userId: this.$cookies.get('user_id'),
      roomId: this.$cookies.get('room_id'),
      cameraId: '',
      teacher_id: Number(this.$cookies.get('school_id')),
      // Number(this.$store.state.teacher_id),
      microphoneId: '',
      room_info: this.$store.state.roomInfo,
    };
  },
  mounted() {
    // eslint-disable-next-line no-debugger
    // debugger;
    // this.teacher_id = Number(this.$store.state.teacher_id);
    clearUrlParam();
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height:100vh;
  padding-bottom: 40px;
  background: url('../assets/image/bg.png');
  background-size: 100% 100%;
  .content {
    width: 80%;
    // margin: 0 auto;
    // max-width: 1320px;
    .alert {
      padding-top: 20px;
      font-size: 16px !important;
    }
    &.content-mobile {
      width: 100%;
      // padding: 0 16px 20px;
    }
    .label {
      margin: 14px 0 6px;
      text-align: left;
      font-weight: bold;
    }
    .param-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        width: calc((100% - 20px) / 2);
        margin-bottom: 10px;
      }
      div:nth-last-child(2), div:nth-last-child(1) {
        margin-bottom: 0;
      }
      &.param-container-mobile {
        div {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

<i18n>
{
	"en": {
		"Params": "Params",
    "Device Select": "Device Select",
    "Alert": "Notes: this Demo is only applicable for debugging. Before official launch, please migrate the UserSig calculation code and key to your backend server to avoid unauthorized traffic use caused by the leakage of encryption key.",
    "Click": "View documents",
    "Url": "https://intl.cloud.tencent.com/document/product/647/35166"
	},
	"zh": {
		"Params": "参数",
    "Device Select": "设备选择",
    "Alert": "注意️：本 Demo 仅用于调试，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。",
    "Click": "查看文档",
    "Url": "https://cloud.tencent.com/document/product/647/17275"
	}
}
</i18n>

